<template>
	<div class="cardDayFlow fadeInRight">
		<div class="title-box clearfix">
			<span class="m-title">网卡每日流量</span>
			<el-form inline ref="operatorForm" class="right themed-form search-form" style="height: auto;line-height: 40px;">
				<el-form-item label="使用量大于/MB：">
					<el-input v-model="operatorFormData.usedFlowGt"></el-input>
				</el-form-item>
				<el-form-item label="ICCID：">
					<el-input v-model="operatorFormData.iccidMark"></el-input>
				</el-form-item>
				<el-form-item label="日期:">
					<el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					 format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>

				<el-form-item label="开卡公司:">
					<el-select v-model="operatorFormData.accountId" placeholder="请选择">
						<el-option v-for="item in accountArr" :key="item.id" :label="item.accountName" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索</el-button>
				</el-form-item>
				<el-form-item>
					<el-button icon="el-icon-caret-left" class="themed-button" type="info" @click="exportExcel">导出Excel</el-button>
				</el-form-item>

			</el-form>
		</div>

		<div class="content-box">
			<el-table :data="tabledata" stripe style="width: 100%;" ref="table" :max-height="maxTableHeight">
				<el-table-column prop="flowDateStr" label="日期">
				</el-table-column>
				<el-table-column prop="iccidMark" label="ICCID">
				</el-table-column>
				<el-table-column prop="phone" label="接入号">
				</el-table-column>
				<el-table-column prop="accountName" label="开卡公司">
				</el-table-column>
				<el-table-column label="当日使用量/GB">
					<template slot-scope="scope">
						<span>{{ parseInt(scope.row.usedFlow/1024.0) }}</span>
					</template>
				</el-table-column>

			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
			 @current-change="onPageChange">
			</el-pagination>
		</div>

	</div>

</template>

<script>
	import loading from '@/utils/Loading.js'
	import {
		carddayflow,
		accountList,
		carddayflowExport
	} from "@/api/welcome.js"
	import onresize from "@/mixins/resize.js"
	export default {
		mixins: [onresize],
		data() {
			return {
				value1: [],
				operatorFormData: {
					usedFlowGt: "",
					iccidMark: "",
					accountId: ""
				},
				accountArr: [],
				page: 1,
				pageSize: 15,
				total: 0,
				tabledata: []

			}
		},
		created() {
			let params = {
				flowDateStart: "",
				flowDateEnd: "",
			}
			Object.assign(params, this.operatorFormData)
			this.getAccountList()
			this.getCardDayFlowList(params)
		},
		methods: {
			getCardDayFlowList(params) {
				carddayflow(params).then(res => {
					console.log(res)
					this.tabledata = res
					this.page = res.pageNo
					this.pageSize = res.pageSize
					this.total = res.total
				})
			},
			getAccountList() {
				accountList({}).then(res => {
					this.accountArr = res

				})
			},
			onSearch() {
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
					flowDateStart: this.value1 == null ? "" : this.value1[0],
					flowDateEnd: this.value1 == null ? "" : this.value1[1],
				}
				Object.assign(params, this.operatorFormData)
				this.getCardDayFlowList(params)
			},
			exportExcel() {
				loading.show()
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
					flowDateStart: this.value1 == null ? "" : this.value1[0],
					flowDateEnd: this.value1 == null ? "" : this.value1[1],
				}
				Object.assign(params, this.operatorFormData)
				carddayflowExport(params).then(res => {
					if (res.system_result_key == '0') {
						this.$alert('导出成功，请到导出记录中下载', '提示', {
							confirmButtonText: '确定',
						}).then(() => {
							this.$router.push('/tob/i_export_list')
						});
					} else {
						this.$message({
							showClose: true,
							message: res.system_result_message_key,
							type: 'error'
						});
					}
				})
			},
			onPageChange(page) {
				this.page = page
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
					flowDateStart: this.value1 == null ? "" : this.value1[0],
					flowDateEnd: this.value1 == null ? "" : this.value1[1],
				}
				Object.assign(params, this.operatorFormData)
				this.getCardDayFlowList(params)
			},
			handleSizeChange(size) {
				this.pageSize = size
				let params = {
					pageSize: this.pageSize,
					pageNo: this.page,
					flowDateStart: this.value1 == null ? "" : this.value1[0],
					flowDateEnd: this.value1 == null ? "" : this.value1[1],
				}
				Object.assign(params, this.operatorFormData)
				this.getCardDayFlowList(params)
			}
		},
	}
</script>

<style>
	.cardDayFlow .el-form-item__label {vertical-align: top;}
</style>
